import {
  facebook, linkedIn, instagram, twitter, youtube, mail,
} from './icon';

const SOCIAL_LIST = [
  {
    id: 'facebook',
    icon: facebook(),
    link: 'https://www.facebook.com/impactfactors.net',
  },
  {
    id: 'linkedIn',
    icon: linkedIn(),
    link: 'https://www.linkedin.com/company/impact-factors-llc ',
  },
  {
    id: 'instagram',
    icon: instagram(),
    link: ' https://www.instagram.com/impactfactorsofficial/',
  },
  {
    id: 'twitter',
    icon: twitter(),
    link: 'https://twitter.com/ImpactFactors',
  },
  {
    id: 'youtube',
    icon: youtube(),
    link: 'https://www.youtube.com/channel/UC41SJAFkZf8x005i95zxoKg',
  },
  {
    id: 'mail',
    icon: mail(),
    link: 'mailto:sales@impactfactors.net',
  },
];

export default SOCIAL_LIST;
