const WHITE_TAB_LIST = [
  {
    id: 1,
    icon: 'website',
    title: 'Website',
    description: 'Your world-class presence in less than 1 hour!',
  },
  {
    id: 2,
    icon: 'heart',
    title: 'Giving Platform',
    description: '(Optional, but included)',
    link: 'Estimate your savings',
    maxWidth: '60px',
  },
  {
    id: 3,
    icon: 'crm',
    title: 'CRM/ChMS',
    description: '(Optional, but included)',
  },
  {
    id: 4,
    icon: 'forms',
    title: 'Forms',
  },
  {
    id: 5,
    icon: 'bulb',
    title: 'Graphic Assistant',
  },
  {
    id: 6,
    icon: 'sale-icon',
    maxWidth: '140px',
  },
];

export default WHITE_TAB_LIST;
