const HELPFUL_LIST = [
  {
    id: 1,
    title: 'Growing Your Audience <br> With Our Free ImpactCRM™',
    duration: 'Watch 35-sec video',
    url: 'https://player.vimeo.com/video/659581757?h=0eafb2d2dd',
  },
  {
    id: 2,
    title: 'Doing All the Work for You',
    subtext: 'Less Work = Higher Engagement',
    duration: 'Watch 30-sec video',
    url: 'https://player.vimeo.com/video/659581628?h=ed2000afd2',
  },
  {
    id: 3,
    title: 'Putting User & Donor<br>Growth on Autopilot',
    duration: 'Watch 1 minute video',
    url: 'https://player.vimeo.com/video/659581851?h=136c38c426',
  },
  {
    id: 4,
    title: 'Expanding Your Reach<br>With Zero Effort',
    duration: 'Watch 34-sec video',
    url: 'https://player.vimeo.com/video/659582008?h=2f6d857b16',
  },
];

export default HELPFUL_LIST;
