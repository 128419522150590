<template>
  <div class="content">
    <!-- ***** SLIDER Area Start ***** -->
    <section
      class="section section-pin pin-wrap-trigger"
      data-interval="false"
    >
      <swiper
        ref="designs"
        :options="swiperOptionSlider"
        class="pin-wrap"
      >
        <swiper-slide>
          <div>
            <section
              id="designs"
              class="ptb_100"
            >
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-11 col-sm-12 col-md-10 col-lg-8">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                      <h2 class="text-black">
                        Stunning <span>{{ new Date().getFullYear() }} Designs</span>
                      </h2>
                      <p class="text-grey my-4 mx-4">
                        Always updated templates gallery.
                        We can also create a custom design just for you or work with your designer.
                      </p>
                      <a
                        class="btn btn-second prolend-primary"
                        href="#"
                        @click.prevent="showModalTemplatesMethod()"
                      >
                        See Templates
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="designs-container">
                <div class="row justify-content-center">
                  <div class="col-12">
                    <div class="faq-content">
                      <div
                        id="prolend-accordion"
                        class="accordion"
                      >
                        <div class="row justify-content-center">
                          <div class="col-12 p-0">
                            <v-lazy-image
                              :src="require(`../home-assets/img/feature-thumb/design-banners${isMobile ? '_mob' : ''}.png`)"
                              :srcPlaceholder="require('../assets/images/loading1.svg')"
                              :alt="`Banners`"
                              :width="isMobile ? 750 : 1621"
                              :height="isMobile ? 948: 841"
                            />
                            <!--                            <img-->
                            <!--                              v-if="!isMobile"-->
                            <!--                              class="avatar mr-2"-->
                            <!--                               src="../home-assets/img/feature-thumb/design-banners.png" alt=""-->
                            <!--                            >-->
                            <!--                            <img-->
                            <!--                              v-if="isMobile"-->
                            <!--                              class="avatar mr-2" src="../home-assets/img/feature-thumb/design-banners_mob.png" alt=""-->
                            <!--                            >-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <section
              id="widgets"
              class="ptb_100"
            >
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-12 col-md-10 col-lg-9">
                    <!-- Section Heading -->
                    <div class="section-heading text-center">
                      <h2 class="text-black">
                        Ready-made <span>Pages, Widgets</span> & Functionalities
                      </h2>
                      <p class="text-grey my-2 mx-4">
                        Built specifically for churches and ministries. <br>
                        Top-notch user experience designed to drive supreme results. <br>
                        <b class="black-mark">You will not find these proprietary features <span class="blue-mark">anywhere else!</span></b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="designs-container">
                <div class="row justify-content-center">
                  <div class="col-12">
                    <div class="faq-content">
                      <div
                        id="prolend-accordion"
                        class="accordion"
                      >
                        <div class="row justify-content-center">
                          <div class="col-12 p-0">
                            <v-lazy-image
                              :src="require(`../home-assets/img/feature-thumb/widgets${isMobile ? '_mob' : ''}.png`)"
                              :srcPlaceholder="require('../assets/images/loading1.svg')"
                              :alt="`Banners`"
                              :width="isMobile ? 751 : 2523"
                              :height="isMobile ? 658: 1132"
                            />
                            <!--                              <img-->
                            <!--                                :src="require(`../home-assets/img/feature-thumb/widgets${isMobile ? '_mob' : ''}.png`)"-->
                            <!--                                :alt="'Banners'"-->
                            <!--                              />-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </swiper-slide>
      </swiper>
      <div
        v-if="false"
        class="pin-wrap"
      >
        <div>
          <section
            id="designs"
            class="ptb_100"
          >
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-11 col-sm-12 col-md-10 col-lg-8">
                  <!-- Section Heading -->
                  <div class="section-heading text-center">
                    <h2 class="text-black">
                      Stunning <span>{{ new Date().getFullYear() }} Designs</span>
                    </h2>
                    <p class="text-grey my-4 mx-4">
                      Always updated templates gallery.
                      We can also create a custom design just for you or work with your designer.
                    </p>
                    <a
                      class="btn prolend-primary"
                      href="#"
                      @click.prevent="showModalTemplatesMethod()"
                    >
                      See Templates
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="designs-container">
              <div class="row justify-content-center">
                <div class="col-12">
                  <div class="faq-content">
                    <div
                      id="prolend-accordion"
                      class="accordion"
                    >
                      <div class="row justify-content-center">
                        <div class="col-12 p-0">
                          <v-lazy-image
                            :src="require(`../home-assets/img/feature-thumb/design-banners${isMobile ? '_mob' : ''}.png`)"
                            :srcPlaceholder="require('../assets/images/loading1.svg')"
                            :alt="`Banners`"
                            :width="isMobile ? 750 : 1621"
                            :height="isMobile ? 948: 841"
                          />
                          <!--                            <img-->
                          <!--                              v-if="!isMobile"-->
                          <!--                              class="avatar mr-2"-->
                          <!--                               src="../home-assets/img/feature-thumb/design-banners.png" alt=""-->
                          <!--                            >-->
                          <!--                            <img-->
                          <!--                              v-if="isMobile"-->
                          <!--                              class="avatar mr-2" src="../home-assets/img/feature-thumb/design-banners_mob.png" alt=""-->
                          <!--                            >-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
          <section
            id="widgets"
            class="ptb_100"
          >
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-9">
                  <!-- Section Heading -->
                  <div class="section-heading text-center">
                    <h2 class="text-black">
                      Ready-made <span>Pages, Widgets</span> & Functionalities
                    </h2>
                    <p class="text-grey my-2 mx-4">
                      Built specifically for churches and ministries. <br>
                      Top-notch user experience designed to drive supreme results. <br>
                      <b class="black-mark">You will not find these proprietary features <span class="blue-mark">anywhere else!</span></b>
                    </p>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-12">
                  <div class="faq-content">
                    <div
                      id="prolend-accordion"
                      class="accordion"
                    >
                      <div class="row justify-content-center">
                        <div class="col-12 p-0">
                          <v-lazy-image
                            :src="require(`../home-assets/img/feature-thumb/widgets${isMobile ? '_mob' : ''}.png`)"
                            :srcPlaceholder="require('../assets/images/loading1.svg')"
                            :alt="`Banners`"
                            :width="isMobile ? 751 : 2523"
                            :height="isMobile ? 658: 1132"
                          />
                          <!--                              <img-->
                          <!--                                :src="require(`../home-assets/img/feature-thumb/widgets${isMobile ? '_mob' : ''}.png`)"-->
                          <!--                                :alt="'Banners'"-->
                          <!--                              />-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
    <!-- ***** SLIDER Area End ***** -->

    <!-- ***** SignUP Area Start ***** -->
    <section
      id="signup"
      class="section grey-area style-two ptb_133"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-7">
            <!-- Section Heading -->
            <div class="section-heading text-center">
              <h2 class="text-black">
                What You’ll Get <br> if You Sign up Today:
              </h2>
              <p class="signup-text text-grey my-4 mx-4 ">
                <img
                  width="32px"
                  height="30px"
                  src="../home-assets/img/icon/star.png"
                  alt="limited spots star"
                  class="signup-star"
                >
                Limited spots available.
                <b class="black-mark">Pricing will not change later.</b><br>
                Unbeatable pricing. No contract. No hidden costs. No setup fee.
              </p>
            </div>
          </div>
        </div>
        <div class="white-tab">
          <div
            v-for="(item, i) in whiteTabList"
            :key="item.id"
            class="white-tab-item"
            :class="item.icon ? `white-tab-item_${item.icon}` : ''"
          >
            <div class="icon-box text-center p-3">
              <!-- Featured Icon -->
              <div
                v-if="item.icon"
                class="mb-3 white-tab-item__icon"
              >
                <img
                  :style="{maxWidth: item.maxWidth}"
                  :src="require(`../home-assets/img/icon/${item.icon}.png`)"
                  :alt="item.title || 'icon'"
                >
              </div>
              <!-- Icon Text -->
              <div
                v-if="item.title || item.description"
                class="text-center"
              >
                <p
                  v-if="item.title"
                  class="h4 font-weight-bold black-mark white-tab-item__title"
                >
                  {{ item.title }}
                </p>
                <p
                  v-if="item.description"
                  class="icon-sign white-tab-item__description"
                >
                  {{ item.description }}
                </p>
                <p
                  v-if="item.link"
                  class="icon-sign white-tab-item__description white-tab-item__description_link"
                  @click="showModalPriceComparison = true"
                >
                  {{ item.link }}
                </p>
              </div>
            </div>
            <p
              v-if="i > 0"
              class="white-tab-item__symbal text-symbal"
              :class="`white-tab-item__symbal_${item.icon}`"
            >
              {{ i === whiteTabList.length - 1 ? '=' : '+' }}
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <!-- Store Buttons -->
          <div class="button-group_today store-buttons d-flex col-12 justify-content-center">
            <a
              class="btn-second button-new-design prolend-primary my-2"
              href="#"
              @click.prevent="showModalGetStarted = true"
            >
              Get started
            </a>
            <a
              class="btn button-new-design prolend-primary my-2"
              href="#"
              @click.prevent="showModalGetStarted = true"
            >
              Lock your discount
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- ***** SignUP Area End ***** -->
    <!-- ***** Reach More Area Start ***** -->
    <section
      id="reachmore"
      class="section section-pin pin-wrap2-trigger"
    >
      <swiper
        ref="reachmore"
        :options="swiperOptionSlider"
        class="pin-wrap2"
      >
        <swiper-slide>
          <div>
            <div class="section-slide1">
              <div class="container">
                <div class="section-row row justify-content-center">
                  <div class="col-12 col-md-10 col-lg-8">
                    <!-- Section Heading -->
                    <div class="text-center slider-header justify-content-center">
                      <h2 class="text-white section-pin-pc__title">
                        Start Small<br> & Expand Anytime
                      </h2>
                      <h6 class="text-dusty section-pin-pc__text">
                        All you need to expand reach & increase engagement. <br>
                        Created by experts, approved by industry leaders.
                        <a
                          style="color: #00CBF8; text-decoration: none"
                          target="_blank"
                          href="https://www.impactfactors.net/tv"
                        >
                          Learn more
                        </a>
                      </h6>
                      <h5 class="text-white my-4 mx-4 text-nowrap section-pin-pc__description">
                        Reach millions with your <span style="color: #00CBF8;">own TV App</span>
                      </h5>
                    </div>
                  </div>
                </div>
                <!--                <img-->
                <!--                  class="w-100"-->
                <!--                  :class="isMobile ? 's_mobile' : 's_desktop'"-->
                <!--                  :src="require(`../home-assets/img/feature-thumb/Slider_2${isMobile ? '_mobile' : ''}.jpg`)"-->
                <!--                  :alt="'Second slide'"-->
                <!--                  :width="isMobile ? 750 : 2040"-->
                <!--                  :height="isMobile ? 1264 : 1080"-->
                <!--                />-->
                <v-lazy-image
                  class="w-100"
                  :class="isMobile ? 's_mobile' : 's_desktop'"
                  :src="require(`../home-assets/img/feature-thumb/Slider_2${isMobile ? '_mobile' : ''}.jpg`)"
                  :srcPlaceholder="require('../assets/images/loading1.svg')"
                  :alt="`Second slide`"
                  :width="isMobile ? 750 : 2040"
                  :height="isMobile ? 1264 : 1080"
                />
                <!--                <img class="w-100 s_desktop" style="width:auto;" src="../home-assets/img/feature-thumb/Slider_2.jpg" alt="Second slide">-->
                <!--                <img class="w-100 s_mobile" style="width:auto;" src="../home-assets/img/feature-thumb/Slider_2_mobile.jpg" alt="Second slide">-->
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <div class="section-slide2">
              <div class="container">
                <div class="section-row row justify-content-center">
                  <div class="col-12 col-md-10 col-lg-7">
                    <!-- Section Heading -->
                    <div class="text-center slider-header justify-content-center">
                      <h2 class="text-white section-pin-pc__title">
                        Your New <br> Mobile App
                      </h2>
                      <h5 class="text-white section-pin-pc__description section-pin-pc__description_app">
                        <span style="color: #FFC225;">The most powerful</span> medium of the century
                      </h5>
                      <h6 class="text-white section-pin-pc__text section-pin-pc__text_app">
                        Start Small & Expand Anytime.
                        <br />
                        All you need to expand reach & increase engagement.
                        Created by experts, approved by industry leaders.
                        <a
                          style="color: #FFC225; text-decoration: none"
                          target="_blank"
                          href="https://www.impactfactors.net/mobile"
                        >
                          Learn more
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
                <!--                <img-->
                <!--                  class="w-100"-->
                <!--                  :class="isMobile ? 's_mobile' : 's_desktop'"-->
                <!--                  :src="require(`../home-assets/img/feature-thumb/Slider_3${isMobile ? '_mobile' : ''}.jpg`)"-->
                <!--                  :alt="'Third slide'"-->
                <!--                />-->
                <v-lazy-image
                  class="w-100"
                  :class="isMobile ? 's_mobile' : 's_desktop'"
                  :src="require(`../home-assets/img/feature-thumb/Slider_3${isMobile ? '_mobile' : ''}.jpg`)"
                  :srcPlaceholder="require('../assets/images/loading1.svg')"
                  :alt="`Third slide`"
                  :width="isMobile ? 750 : 2041"
                  :height="isMobile ? 1264 : 1080"
                />
                <!--                <img class="w-100 s_desktop" style="width:auto;" src="../home-assets/img/feature-thumb/Slider_3.jpg" alt="Third slide">-->
                <!--                <img class="w-100 s_mobile" style="width:auto;" src="../home-assets/img/feature-thumb/Slider_3_mobile.jpg" alt="Third slide">-->
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div>
            <div class="section-slide3">
              <div class="container">
                <div class="section-row section-row_slide3 row justify-content-center s_header">
                  <div class="col-12 col-md-10 col-lg-7">
                    <!-- Section Heading -->
                    <div class="text-center slider-header justify-content-center">
                      <h2 class="text-black">
                        Reach <br> More People
                      </h2>
                      <h5 class="text-black my-4 mx-4 text-nowrap">
                        by Expanding to <span style="color: #1ED760;">Podcasting Platforms</span>
                      </h5>
                      <h6 class="text-black my-4 mx-4">
                        Start Small & Expand Anytime.<br> More than half of consumers in the US listen <br> to podcasts (Statista, 2019)
                      </h6>
                    </div>
                  </div>
                </div>
                <!--                <img-->
                <!--                  class="w-100"-->
                <!--                  :class="isMobile ? 's_mobile' : 's_desktop'"-->
                <!--                  :src="require(`../home-assets/img/feature-thumb/Slider_1${isMobile ? '_mobile' : ''}.jpg`)"-->
                <!--                  :alt="'First slide'"-->
                <!--                />-->
                <v-lazy-image
                  class="w-100"
                  :class="isMobile ? 's_mobile' : 's_desktop'"
                  :src="require(`../home-assets/img/feature-thumb/Slider_1${isMobile ? '_mobile' : ''}.jpg`)"
                  :srcPlaceholder="require('../assets/images/loading1.svg')"
                  :alt="`First slide`"
                  :width="isMobile ? 750 : 2040"
                  :height="isMobile ? 1264 : 1080"
                />
                <!--                <img class="w-100 s_desktop" style="width:auto;" src="../home-assets/img/feature-thumb/Slider_1.jpg" alt="First slide">-->
                <!--                <img class="w-100 s_mobile" src="../home-assets/img/feature-thumb/Slider_1_mobile.jpg" alt="First slide">-->
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div
        v-if="false"
        class="pin-wrap2"
      >
        <div>
          <div class="section-slide1">
            <div class="container">
              <div class="section-row row justify-content-center">
                <div class="col-12 col-md-10 col-lg-8">
                  <!-- Section Heading -->
                  <div class="text-center slider-header justify-content-center">
                    <h2 class="text-white section-pin-pc__title">
                      Start Small<br> & Expand Anytime
                    </h2>
                    <h6 class="text-dusty section-pin-pc__text">
                      All you need to expand reach & increase engagement. <br>
                      Created by experts, approved by industry leaders.
                      <a
                        style="color: #00CBF8; text-decoration: none"
                        target="_blank"
                        href="https://www.impactfactors.net/tv"
                      >
                        Learn more
                      </a>
                    </h6>
                    <h5 class="text-white my-4 mx-4 text-nowrap section-pin-pc__description">
                      Reach millions with your <span style="color: #00CBF8;">own TV App</span>
                    </h5>
                  </div>
                </div>
              </div>
              <!--                <img-->
              <!--                  class="w-100"-->
              <!--                  :class="isMobile ? 's_mobile' : 's_desktop'"-->
              <!--                  :src="require(`../home-assets/img/feature-thumb/Slider_2${isMobile ? '_mobile' : ''}.jpg`)"-->
              <!--                  :alt="'Second slide'"-->
              <!--                  :width="isMobile ? 750 : 2040"-->
              <!--                  :height="isMobile ? 1264 : 1080"-->
              <!--                />-->
              <v-lazy-image
                class="w-100"
                :class="isMobile ? 's_mobile' : 's_desktop'"
                :src="require(`../home-assets/img/feature-thumb/Slider_2${isMobile ? '_mobile' : ''}.jpg`)"
                :srcPlaceholder="require('../assets/images/loading1.svg')"
                :alt="`Second slide`"
                :width="isMobile ? 750 : 2040"
                :height="isMobile ? 1264 : 1080"
              />
            <!--                <img class="w-100 s_desktop" style="width:auto;" src="../home-assets/img/feature-thumb/Slider_2.jpg" alt="Second slide">-->
            <!--                <img class="w-100 s_mobile" style="width:auto;" src="../home-assets/img/feature-thumb/Slider_2_mobile.jpg" alt="Second slide">-->
            </div>
          </div>
        </div>
        <div>
          <div class="section-slide2">
            <div class="container">
              <div class="section-row row justify-content-center">
                <div class="col-12 col-md-10 col-lg-7">
                  <!-- Section Heading -->
                  <div class="text-center slider-header justify-content-center">
                    <h2 class="text-white section-pin-pc__title">
                      Your New <br> Mobile App
                    </h2>
                    <h5 class="text-white section-pin-pc__description section-pin-pc__description_app">
                      <span style="color: #FFC225;">The most powerful</span> medium of the century
                    </h5>
                    <h6 class="text-white section-pin-pc__text section-pin-pc__text_app">
                      Start Small & Expand Anytime.
                      <br />
                      All you need to expand reach & increase engagement.
                      Created by experts, approved by industry leaders.
                      <a
                        style="color: #FFC225; text-decoration: none"
                        target="_blank"
                        href="https://www.impactfactors.net/mobile"
                      >
                        Learn more
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
              <!--                <img-->
              <!--                  class="w-100"-->
              <!--                  :class="isMobile ? 's_mobile' : 's_desktop'"-->
              <!--                  :src="require(`../home-assets/img/feature-thumb/Slider_3${isMobile ? '_mobile' : ''}.jpg`)"-->
              <!--                  :alt="'Third slide'"-->
              <!--                />-->
              <v-lazy-image
                class="w-100"
                :class="isMobile ? 's_mobile' : 's_desktop'"
                :src="require(`../home-assets/img/feature-thumb/Slider_3${isMobile ? '_mobile' : ''}.jpg`)"
                :srcPlaceholder="require('../assets/images/loading1.svg')"
                :alt="`Third slide`"
                :width="isMobile ? 750 : 2041"
                :height="isMobile ? 1264 : 1080"
              />
            <!--                <img class="w-100 s_desktop" style="width:auto;" src="../home-assets/img/feature-thumb/Slider_3.jpg" alt="Third slide">-->
            <!--                <img class="w-100 s_mobile" style="width:auto;" src="../home-assets/img/feature-thumb/Slider_3_mobile.jpg" alt="Third slide">-->
            </div>
          </div>
        </div>
        <div>
          <div class="section-slide3">
            <div class="container">
              <div class="section-row section-row_slide3 row justify-content-center s_header">
                <div class="col-12 col-md-10 col-lg-7">
                  <!-- Section Heading -->
                  <div class="text-center slider-header justify-content-center">
                    <h2 class="text-black">
                      Reach <br> More People
                    </h2>
                    <h5 class="text-black my-4 mx-4 text-nowrap">
                      by Expanding to <span style="color: #1ED760;">Podcasting Platforms</span>
                    </h5>
                    <h6 class="text-black my-4 mx-4">
                      Start Small & Expand Anytime.<br> More than half of consumers in the US listen <br> to podcasts (Statista, 2019)
                    </h6>
                  </div>
                </div>
              </div>
              <!--                <img-->
              <!--                  class="w-100"-->
              <!--                  :class="isMobile ? 's_mobile' : 's_desktop'"-->
              <!--                  :src="require(`../home-assets/img/feature-thumb/Slider_1${isMobile ? '_mobile' : ''}.jpg`)"-->
              <!--                  :alt="'First slide'"-->
              <!--                />-->
              <v-lazy-image
                class="w-100"
                :class="isMobile ? 's_mobile' : 's_desktop'"
                :src="require(`../home-assets/img/feature-thumb/Slider_1${isMobile ? '_mobile' : ''}.jpg`)"
                :srcPlaceholder="require('../assets/images/loading1.svg')"
                :alt="`First slide`"
                :width="isMobile ? 750 : 2040"
                :height="isMobile ? 1264 : 1080"
              />
            <!--                <img class="w-100 s_desktop" style="width:auto;" src="../home-assets/img/feature-thumb/Slider_1.jpg" alt="First slide">-->
            <!--                <img class="w-100 s_mobile" src="../home-assets/img/feature-thumb/Slider_1_mobile.jpg" alt="First slide">-->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ***** Reach More Area End ***** -->
    <!-- ***** Product Features Area Start ***** -->
    <section
      id="feedback"
      class="section background-black feedback-area overflow-hidden ptb_100"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-12 col-lg-12">
            <!-- Section Heading -->
            <div class="section-heading text-center">
              <h2 class="text-white feedback-title">
                Positive Feedback About Solutions <br>That Our Team Created
              </h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-6 col-lg-4">
            <!-- Icon Box -->
            <div class="icon-box text-center p-3">
              <!-- Featured Icon -->
              <div class="featured-icon mb-3 featured-icon_mobile">
                <img
                  src="../home-assets/img/avatar/avatar-1.png"
                  alt=""
                >
              </div>
              <!-- Icon Text -->
              <div class="icon-text text-white">
                <p class="fb-body">
                  “Impact Factors has an absolutely new approach
                  when it comes to technology, strategy, and long-term growth.”
                </p>
                <p class="fb-name">
                  Googz Moffats
                </p>
                <p class="fb-sign">
                  Wisdom Center Church
                </p>
              </div>
            </div>
          </div>
          <div
            class="col-6 col-md-6 col-lg-4"
            style="border-left: 1px solid #333333;"
          >
            <!-- Icon Box -->
            <div class="icon-box text-center p-3">
              <!-- Featured Icon -->
              <div class="featured-icon mb-3 featured-icon_mobile">
                <img
                  src="../home-assets/img/avatar/avatar-2.png"
                  alt=""
                >
              </div>
              <!-- Icon Text -->
              <div class="icon-text text-white">
                <p class="fb-body">
                  “…A whole new level, a new horizon. Thank you for helping us reach millions of people around the world.”
                </p>
                <p class="fb-name">
                  Nick Vujicic
                </p>
                <p class="fb-sign">
                  Life Without Limbs
                </p>
              </div>
            </div>
          </div>
          <div
            class="col-6 col-md-6 col-lg-4"
            style="border-left: 1px solid #333333;"
          >
            <!-- Icon Box -->
            <div class="icon-box text-center p-3">
              <!-- Featured Icon -->
              <div class="featured-icon mb-3 featured-icon_mobile">
                <img
                  src="../home-assets/img/avatar/avatar-3.png"
                  alt=""
                >
              </div>
              <!-- Icon Text -->
              <div class="icon-text text-white">
                <p class="fb-body">
                  “We have switched from another provider and not only saved more than 50% but also received much better solution.”
                </p>
                <p class="fb-name">
                  Delisa B. Ndhlovu
                </p>
                <p class="fb-sign">
                  Amazing Grace SDAC
                </p>
              </div>
            </div>
          </div>
          <div class="left-decoration-sm col-6 col-md-6 col-lg-4">
            <!-- Icon Box -->
            <div class="icon-box text-center p-3">
              <!-- Featured Icon -->
              <div class="featured-icon mb-3 featured-icon_mobile">
                <img
                  src="../home-assets/img/avatar/avatar-4.png"
                  alt=""
                >
              </div>
              <!-- Icon Text -->
              <div class="icon-text text-white">
                <p class="fb-body">
                  “… Innovative to meet all of our current and future needs as well as simplistic enough that everyone can use it.”
                </p>
                <p class="fb-name">
                  Chris Emmitt Executive
                </p>
                <p class="fb-sign">
                  Pastor at Community Bible Church
                </p>
              </div>
            </div>
          </div>
          <div class="left-decoration col-6 col-md-6 col-lg-4">
            <!-- Icon Box -->
            <div class="icon-box text-center p-3">
              <!-- Featured Icon -->
              <div class="featured-icon mb-3 featured-icon_mobile">
                <img
                  src="../home-assets/img/avatar/avatar-5.png"
                  alt=""
                >
              </div>
              <!-- Icon Text -->
              <div class="icon-text text-white">
                <p class="fb-body">
                  “We truly enjoyed working with ImpactFactors. They deliver what they promise and do it with excellence!”
                </p>
                <p class="fb-name">
                  Pastor Brooks
                </p>
                <p class="fb-sign">
                  New Beginnings Church of Chicago
                </p>
              </div>
            </div>
          </div>
          <div class="left-decoration col-6 col-md-6 col-lg-4">
            <!-- Icon Box -->
            <div class="icon-box text-center p-3">
              <!-- Featured Icon -->
              <div class="featured-icon mb-3 featured-icon_mobile">
                <img
                  src="../home-assets/img/avatar/avatar-6.png"
                  alt=""
                >
              </div>
              <!-- Icon Text -->
              <div class="icon-text text-white">
                <p class="fb-body">
                  “Search no more. It’s the best platform out there!”
                </p>
                <p class="fb-name">
                  Shawn Brann
                </p>
                <p class="fb-sign">
                  Ignite Europe
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ***** Product Features Area End ***** -->

    <!-- ***** Trusted Area Start ***** -->
    <section
      id="trusted"
      class="section grey-area style-two ptb_100 trusted-styles"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-10 col-md-10 col-lg-10">
            <!-- Section Heading -->
            <div class="section-heading text-center">
              <h2 class="text-black trusted__title">
                Trusted by Some of The World's Most Advanced Ministries
              </h2>
            </div>
          </div>
        </div>
        <div class="container row justify-content-center">
          <div class="row col-12 trusted-items">
            <div class="row trusted-images align-self-center">
              <div class="col-lg col-md col-sm-6 col-6 align-self-center mb-4">
                <img
                  class="rusted-images__img rusted-images__img_1"
                  src="../home-assets/img/logo/kcm-logo.svg"
                  alt=""
                >
              </div>
              <div class="col-lg col-md col-sm-6 col-6 align-self-center  mb-4">
                <img
                  class="rusted-images__img rusted-images__img_2"
                  src="../home-assets/img/logo/cdm-logo.svg"
                  alt=""
                >
              </div>
              <div class="col-lg col-md col-sm-6 col-6 align-self-center  mb-4">
                <img
                  class="rusted-images__img rusted-images__img_3"
                  src="../home-assets/img/logo/CBC-logo.png"
                  alt=""
                >
              </div>
              <div class="col-lg col-md col-sm-6 col-6 align-self-center  mb-4">
                <img
                  class="rusted-images__img rusted-images__img_4"
                  src="../home-assets/img/logo/Rccg_logo.png"
                  alt=""
                >
              </div>
              <div class="col-lg col-md col-sm-6 col-6 align-self-center  mb-4">
                <img
                  class="rusted-images__img rusted-images__img_5"
                  src="../home-assets/img/logo/lwl-logo.svg"
                  alt=""
                >
              </div>
              <div class="col-sm-6 col-6 align-self-center img-hide  mb-4">
                <img
                  class="rusted-images__img rusted-images__img_6"
                  src="../home-assets/img/logo/N-logo.png"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <!-- Store Buttons -->
          <div class="button-group store-buttons d-flex col-12 justify-content-center">
            <a
              class="btn-second button-new-design prolend-primary my-2"
              href="#"
              @click.prevent="showModalGetStarted = true"
            >
              Get started
            </a>
            <a
              class="btn button-new-design prolend-primary my-2"
              href="#"
              @click.prevent="showModalGetStarted = true"
            >
              Reserve your spot
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- ***** Trusted Area End ***** -->

    <!-- ***** Service Area Start ***** -->
    <section class="service-area background-black ptb_100 service-area-padding">
      <div
        class="parallax"
        style="color: rgba(255, 255, 255, 0.42);"
      >
        <div id="stars1"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
      </div>
      <div
        id="snowflakeContainer"
        class="container"
      >
        <span class="snowflake"></span>
      </div>
      <div class="container">
        <div class="slider-tab container-lg my-3 carousel-container">
          <!--              <div id="myCarousel" class="carousel slide carousel-container-card" data-ride="carousel">-->
          <!--                &lt;!&ndash; Carousel indicators &ndash;&gt;-->
          <!--                <ol class="carousel-indicators">-->
          <!--                  <li data-target="#myCarousel" data-slide-to="0" class="active"></li>-->
          <!--                  <li data-target="#myCarousel" data-slide-to="1"></li>-->
          <!--                  <li data-target="#myCarousel" data-slide-to="2"></li>-->
          <!--                  <li data-target="#myCarousel" data-slide-to="3"></li>-->
          <!--                  <li data-target="#myCarousel" data-slide-to="4"></li>-->
          <!--                </ol>-->
          <!--                &lt;!&ndash; Wrapper for carousel items &ndash;&gt;-->
          <!--                <div class="carousel-inner">-->
          <!--                  <div class="carousel-item active">-->
          <!--                    <img style="width:auto;max-width: 134px" src="../home-assets/img/icon/msg.png" alt="">-->
          <!--                    <h2 class="carousel-item-header">Did you know that:</h2>-->
          <!--                    <p>“Covid-19 Sparked Digital Awakening for Churches.<br>-->
          <!--                      Demand for faith- based content surged 900%”</p>-->
          <!--                  </div>-->
          <!--                  <div class="carousel-item">-->
          <!--                    <img style="width:auto;max-width: 134px" src="../home-assets/img/icon/msg.png" alt="">-->
          <!--                    <h2 class="carousel-item-header">Did you know that:</h2>-->
          <!--                    <p>“The Pandemic has given more people reason to see solace in God. <br>-->
          <!--                      Membership in spiritual apps has surged”</p>-->
          <!--                  </div>-->
          <!--                  <div class="carousel-item">-->
          <!--                    <img style="width:auto;max-width: 134px" src="../home-assets/img/icon/msg.png" alt="">-->
          <!--                    <h2 class="carousel-item-header">Did you know that:</h2>-->
          <!--                    <p>“The top Christian apps raked up 325% (source App Annie)”</p>-->
          <!--                  </div>-->
          <!--                  <div class="carousel-item">-->
          <!--                    <img style="width:auto;max-width: 134px" src="../home-assets/img/icon/msg.png" alt="">-->
          <!--                    <h2 class="carousel-item-header">Did you know that:</h2>-->
          <!--                    <p>“Downloads for <a href="pray.com" target="_blank">Pray.com</a> skyrocketed 955% during the pandemic”</p>-->
          <!--                  </div>-->
          <!--                  <div class="carousel-item">-->
          <!--                    <img style="width:auto;max-width: 134px" src="../home-assets/img/icon/msg.png" alt="">-->
          <!--                    <h2 class="carousel-item-header">Did you know that:</h2>-->
          <!--                    <p>“What worked yesterday is not working today.”</p>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                &lt;!&ndash; Carousel controls &ndash;&gt;-->
          <!--                <a class="carousel-control-prev" href="#myCarousel" data-slide="prev">-->
          <!--                  <span class="carousel-control-prev-icon"></span>-->
          <!--                </a>-->
          <!--                <a class="carousel-control-next" href="#myCarousel" data-slide="next">-->
          <!--                  <span class="carousel-control-next-icon"></span>-->
          <!--                </a>-->
          <!--              </div>-->
          <div
            id="myCarousel"
            class="carousel slide carousel-container-card"
          >
            <!-- Carousel indicators -->
            <div class="carousel-indicators">
              <!--                  <li data-target="#myCarousel" data-slide-to="0" class="active"></li>-->
              <!--                  <li data-target="#myCarousel" data-slide-to="1"></li>-->
              <!--                  <li data-target="#myCarousel" data-slide-to="2"></li>-->
              <!--                  <li data-target="#myCarousel" data-slide-to="3"></li>-->
              <!--                  <li data-target="#myCarousel" data-slide-to="4"></li>-->
            </div>
            <a
              class="carousel-control-prev"
              href="#myCarousel"
            >
              <span class="carousel-control-prev-icon"></span>
            </a>
            <a
              class="carousel-control-next"
              href="#myCarousel"
            >
              <span class="carousel-control-next-icon"></span>
            </a>
            <swiper
              ref="swiperThumbs"
              :options="swiperOption"
              class="carousel-inner"
            >
              <swiper-slide
                class="carousel-item"
                :class="{'active': activeSlide === 0}"
              >
                <img
                  style="width:auto;max-width: 134px"
                  src="../home-assets/img/icon/msg.png"
                  alt=""
                >
                <h2 class="carousel-item-header">
                  Did you know that:
                </h2>
                <p>
                  “Covid-19 Sparked Digital Awakening for Churches.<br>
                  Demand for faith- based content surged 900%”
                </p>
              </swiper-slide>
              <swiper-slide
                class="carousel-item"
                :class="{'active': activeSlide === 1}"
              >
                <img
                  style="width:auto;max-width: 134px"
                  src="../home-assets/img/icon/msg.png"
                  alt=""
                >
                <h2 class="carousel-item-header">
                  Did you know that:
                </h2>
                <p>
                  “The Pandemic has given more people reason to seek solace in God. <br>
                  Membership in spiritual apps has surged”
                </p>
              </swiper-slide>
              <swiper-slide
                class="carousel-item"
                :class="{'active': activeSlide === 2}"
              >
                <img
                  style="width:auto;max-width: 134px"
                  src="../home-assets/img/icon/msg.png"
                  alt=""
                >
                <h2 class="carousel-item-header">
                  Did you know that:
                </h2>
                <p>“The top Christian apps raked up 325% (source App Annie)”</p>
              </swiper-slide>
              <swiper-slide
                class="carousel-item"
                :class="{'active': activeSlide === 3}"
              >
                <img
                  style="width:auto;max-width: 134px"
                  src="../home-assets/img/icon/msg.png"
                  alt=""
                >
                <h2 class="carousel-item-header">
                  Did you know that:
                </h2>
                <p>
                  “Downloads for
                  Pray.com skyrocketed 955% during the pandemic”
                </p>
              </swiper-slide>
              <swiper-slide
                class="carousel-item"
                :class="{'active': activeSlide === 4}"
              >
                <img
                  style="width:auto;max-width: 134px"
                  src="../home-assets/img/icon/msg.png"
                  alt=""
                >
                <h2 class="carousel-item-header">
                  Did you know that:
                </h2>
                <p>“What worked yesterday is not working today.”</p>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-12 col-lg-6 mt-4">
            <h5 class="tizer-txt">
              HERE FOR YOU
            </h5>
            <h2 class="promo-text tizer-title text-white">
              We've got you <br>covered
            </h2>
          </div>

          <div class="col-12 col-lg-6 order-1">
            <!-- Service Text -->
            <div class="service-text pt-4 pt-lg-0">
              <div class="row justify-content-center">
                <!-- Store Buttons -->
                <div class="button-group button-group_yellow  store-buttons d-flex col-12 justify-content-center">
                  <a
                    href="#"
                    class="yellow_btn button-new-design prolend-primary style-two  mt-2 text-center"
                    @click.prevent="showModalGetStarted = true"
                  >
                    Get started
                  </a>
                  <a
                    href="mailto:sales@impactfactors.net"
                    class="yellow_btn_second button-new-design prolend-primary  mt-2 text-center"
                  >
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <!-- Service Thumb -->
            <div class="text-white mx-auto text-left pt-4 pt-lg-0">
              <p>You've helped so many people; it's time for someone to help you. You study, pray, and work just as hard, maybe even harder than pastors and leaders of big mega-churches. You need help, you don't have deep pockets, and you feel like you are all alone. It's time for somebody to help you. </p><br>
              <p>We've made sure you have everything you need to succeed in these unprecedented times. Grow with the next-gen tools, built to increase your ministry's footprint and navigate into the future.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ***** Service Area End ***** -->

    <!-- ***** Widgets Area Start ***** -->
    <section
      id="features"
      class="section style-two bg-white ptb_100 section_features"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-7">
            <!-- Section Heading -->
            <div class="section-heading text-center">
              <h2 class="text-black">
                Features
              </h2>
            </div>
          </div>
        </div>
        <div class="features-icons row justify-content-between text-center align-self-center ">
          <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
            <img
              src="../home-assets/img/icon/CirclesFour.svg"
              alt=""
            >
            <div class="features-text">
              Innovative blocks <br> engine
            </div>
          </div>
          <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
            <img
              src="../home-assets/img/icon/NotePencil.svg"
              alt=""
            >
            <div class="features-text">
              Easy to use <br> editor
            </div>
          </div>
          <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
            <img
              src="../home-assets/img/icon/ClipboardText.svg"
              alt=""
            >
            <div class="features-text">
              Forms builder <br> with payments
            </div>
          </div>
          <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
            <img
              src="../home-assets/img/icon/Stack.svg"
              alt=""
            >
            <div class="features-text">
              Ready-made <br> pages & modules
            </div>
          </div>
          <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
            <img
              src="../home-assets/img/icon/infinity.svg"
              alt=""
            >
            <div class="features-text">
              Unlimited <br> pages
            </div>
          </div>
          <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
            <img
              src="../home-assets/img/icon/PuzzlePiece.svg"
              alt=""
            >
            <div class="features-text">
              Latest design <br> templates
            </div>
          </div>
          <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
            <img
              src="../home-assets/img/icon/FacebookLogo.svg"
              alt=""
            >
            <div class="features-text">
              YouTube <br> & Facebook live
            </div>
          </div>
          <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
            <img
              src="../home-assets/img/icon/VimeoLogo.svg"
              alt=""
            >
            <div class="features-text">
              Vimeo <br> integration
            </div>
          </div>
          <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
            <img
              src="../home-assets/img/icon/YoutubeLogo.svg"
              alt=""
            >
            <div class="features-text">
              YouTube <br> integration
            </div>
          </div>
          <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
            <img
              src="../home-assets/img/icon/ThumbsUp.svg"
              alt=""
            >
            <div class="features-text">
              Social media <br> integration
            </div>
          </div>
          <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
            <img
              src="../home-assets/img/icon/ChatCircleText.svg"
              alt=""
            >
            <div class="features-text">
              Customer <br> support
            </div>
          </div>
          <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
            <img
              src="../home-assets/img/icon/GitBranch.svg"
              alt=""
            >
            <div class="features-text">
              Hosting
            </div>
          </div>
        </div>
        <transition name="fade-block-wait">
          <div
            v-if="showExpand"
            class="features-icons row justify-content-center text-center"
          >
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Circles.svg"
                alt=""
              >
              <div class="features-text">
                Al image processing
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Picture.svg"
                alt=""
              >
              <div class="features-text">
                Millions of free images & icons
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Publish.svg"
                alt=""
              >
              <div class="features-text">
                Auto-published devotions & reading plan
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Camera.svg"
                alt=""
              >
              <div class="features-text">
                Banners & artwork gallery
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Fonts.svg"
                alt=""
              >
              <div class="features-text">
                Premium fonts gallery (free)
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/User.svg"
                alt=""
              >
              <div class="features-text">
                User profile & registration
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Copy.svg"
                alt=""
              >
              <div class="features-text">
                Copy templates
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Widgets.svg"
                alt=""
              >
              <div class="features-text">
                Smart widgets
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Scheduled.svg"
                alt=""
              >
              <div class="features-text">
                Scheduled publishing
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Mobile.svg"
                alt=""
              >
              <div class="features-text">
                Mobile & tablet adaptive
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Layers.svg"
                alt=""
              >
              <div class="features-text">
                Layers and guides
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Rocket.svg"
                alt=""
              >
              <div class="features-text">
                Cutting-edge technology stack
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Tickets.svg"
                alt=""
              >
              <div class="features-text">
                Tickets
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Google.svg"
                alt=""
              >
              <div class="features-text">
                Google analytics
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Seo.svg"
                alt=""
              >
              <div class="features-text">
                SEO optimized
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/SSL.svg"
                alt=""
              >
              <div class="features-text">
                Free SSL certificate
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Coockie.svg"
                alt=""
              >
              <div class="features-text">
                GDPR & cookie policy
              </div>
            </div>
            <div class="features-icon col-md-3 col-lg-3 col-sm-6 col-6">
              <img
                src="../home-assets/img/icon/Billing.svg"
                alt=""
              >
              <div class="features-text">
                Integrated billing
              </div>
            </div>
          </div>
        </transition>
        <!-- Collapse Button -->
        <div class="row justify-content-center">
          <a
            class="btn prolend-primary btn-feature"
            data-toggle="collapse"
            href="#hide"
            @click.prevent="onClickFeature"
          >
            {{ showExpand ? 'Hide' : 'See' }} all features
          </a>
        </div>
      </div>
    </section>
    <!-- ***** Widgets Area End ***** -->
    <!-- ***** Discover Area Start ***** -->
    <section class="section rocket-area overflow-hidden pt_100">
      <div class="container">
        <div
          v-if="!usa"
          class="rocket-area-country"
        >
          <div class="rocket-area-country__title">
            Helping Ministries <span>in Africa Grow Locally</span> & Internationally
          </div>
          <div class="rocket-area-country-list">
            <div
              v-for="country in countryList"
              :key="country.id"
              class="rocket-area-country-list__item"
            >
              <img
                :src="require(`../assets/images/flag/${country.img}`)"
                alt=""
              >
            </div>
          </div>
          <div class="rocket-area-country__text">
            and others!
          </div>
        </div>

        <div class="rocket-area-row">
          <div class="rocket-area-item-container">
            <div class="rocket-area-item rocket-area-item_first">
              <div class="rocket-area-item__icon">
                <img
                  src="../home-assets/img/icon/phoneCall.svg"
                  alt="phone"
                >
              </div>
              <div class="rocket-area-item__text">
                Schedule call <br>
                with our specialist
              </div>
              <a
                class="btn-second rocket-area-item__btn"
                href="https://calendly.com/d/cmv-mf2-9zh/demo-of-impactfactors-vip-50-discount?month=2022-01"
                rel="noopener"
                target="_blank"
              >
                Book an appointment
              </a>
            </div>
          </div>
          <div class="rocket-area-item-container">
            <div class="rocket-area-item rocket-area-item_second">
              <div class="rocket-area-item__icon">
                <img
                  src="../home-assets/img/icon/alarm.svg"
                  alt="alarm"
                >
              </div>
              <div class="rocket-area-item__text">
                This offer ends in:
              </div>
              <div>
                <counter
                  class="rocket-area-item__counter"
                  :startDate="dateEnd"
                  :color="'#969696'"
                />
              </div>
              <button
                class="btn-second rocket-area-item__btn"
                @click.prevent="showModalGetStarted = true"
              >
                Reserve your spot
              </button>
            </div>
          </div>
          <div class="rocket-area-item-container">
            <div class="rocket-area-item">
              <div class="rocket-area-item__icon">
                <img
                  src="../home-assets/img/icon/sword.svg"
                  alt="sword"
                >
              </div>
              <div class="rocket-area-item__text">
                We save you time <br>
                on competitor research
                <span>For our full package</span>
              </div>
              <button
                class="btn-second rocket-area-item__btn btn-website-download-chart"
                @click.prevent="showModalFormStatus = true"
              >
                Download chart
              </button>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-12 col-lg-6 order-2 order-lg-1">
            <!-- Discover Thumb -->
            <div class="service-thumb discover-thumb mx-auto text-center pt-4 pt-lg-0">
              <img
                class="cloud_3 img-fluid"
                src="../home-assets/img/bg/footer_cloud_3.png"
                alt=""
              >
              <img
                class="cloud_2 img-fluid"
                src="../home-assets/img/bg/footer_cloud_2.png"
                alt=""
              >
              <img
                class="cloud_1 img-fluid"
                src="../home-assets/img/bg/footer_cloud_1.png"
                alt=""
              >
              <img
                class="footer_rocket"
                src="../home-assets/img/feature-thumb/rocket.png"
                alt=""
              >
            </div>
          </div>
          <div class="col-12 col-lg-6 order-2 order-lg-2">
            <!-- Discover Text -->
            <div class="discover-text pt-lg-0">
              <h2 class="promo-text promo-text-footer mb-4 font-weight-bold">
                Expand Your Reach With a World-class Presence Today!<span class="white-text"><br>Only $25/month</span>
              </h2>
              <!-- Service List -->
              <ul class="service-list">
                <!-- Service Button -->
                <div class="row service_btn single-service py-2 mx-auto">
                  <a
                    class="rocket_btn"
                    href="#"
                    @click.prevent="showModalGetStarted = true"
                  >
                    Get started
                  </a>
                </div>
                <!-- Single Service -->
                <li class="single-service media py-2">
                  <p class="my-2 text-white star star_new-title h6">
                    <img
                      class="mr-2 star_new"
                      src="../home-assets/img/icon/star.png"
                      alt=""
                    >No Contract. No hidden pricing.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Rights Area -->
      <!--      <div class="d-flex flex-wrap justify-content-center text-center py-4">-->
      <!--        <div class="rights-center">&copy; 2021 All Rights Reserved. Impact Factors, LLC.</div>-->
      <!--      </div>-->
    </section>
    <section
      v-if="false"
      class="section websites-slider"
    >
      <!--      <WebsitesSlider />-->
      <div class="websites-slider-bg">
        <h2 class="text-black text-center">
          Recently Launched Websites
        </h2>
        <div class="websites-slider-container">
          <swiper
            ref="designs"
            :options="swiperOptionSliderWebsites"
            class="pin-wrap"
          >
            <swiper-slide
              v-for="(slide, i) in websitesSlides"
              :key="slide.img"
              class="websites-slider-slide"
            >
              <div
                v-if="i === 0"
                class="websites-slider-demo"
              >
                Demo version
              </div>
              <div>
                <div class="designs-container">
                  <div>
                    <div
                      class="websites-slider-padding"
                    >
                      <div class="websites-slider-wrapper">
                        <div>
                          <v-lazy-image
                            :src="slide.img"
                            :srcPlaceholder="require('../assets/images/loading1.svg')"
                            :alt="`Banners`"
                            class="websites-slider-img"
                          />
                        </div>
                        <div>
                          <a
                            :href="slide.link"
                            target="_blank"
                            class="websites-slider-button"
                            @click="clickMethod($event, slide)"
                          >

                            Visit Website
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
    <!-- ***** Discover Area End ***** -->
    <Helpful />
    <section class="dark-bg">
      <Faq :isMobile="isMobile" />
    </section>
    <!--====== Footer Area Start ======-->
    <footer class="footer-area dark-bg">
      <!-- Footer Bottom -->
      <div class="footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="footer-social">
                <a
                  v-for="item in socialList"
                  :key="item.id"
                  class="footer-social__item"
                  :href="item.link"
                  target="_blank"
                  :title="item.id"
                  v-html="item.icon"
                />
              </div>
              <div class="footer-verified">
                <img
                  src="../assets/images/lock.svg"
                  alt="lock"
                >
                Verified & Secured
              </div>
              <div class="footer-card">
                <div class="footer-card__item">
                  <img
                    src="../assets/images/card1.svg"
                    alt="visa"
                  >
                </div>
                <div class="footer-card__item">
                  <img
                    src="../assets/images/card2.svg"
                    alt="master card"
                  >
                </div>
                <div class="footer-card__item">
                  <img
                    src="../assets/images/card3.svg"
                    alt="american express"
                  >
                </div>
                <div class="footer-card__item">
                  <img
                    src="../assets/images/card4.svg"
                    alt="discover"
                  >
                </div>
              </div>
              <div class="footer-info">
                <div>
                  <span class="footer-info__organization">Impact Factors, LLC,</span>
                  3626 N Hall Str, Suite 610, Dallas, TX, 75219, USA
                </div>
                <div>
                  © {{ currentYear }} All Rights Reserved.
                  <span
                    class="footer-info__link"
                    @click.prevent="showModalPrivacyPolicy = true"
                  >
                    Privacy Policy
                  </span>
                </div>
              </div>
              <!-- Copyright Area -->
              <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                <!-- Copyright Bottom-->
                <div class="copyright-center">
                  <a href="#">
                    <img
                      class="navbar-brand-regular"
                      src="../assets/images/footer-logo.svg"
                      alt="logo"
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!--====== Footer Area End ======-->
    <transition
      name="animate-unfolding"
      appear
      mode="out-in"
    >
      <div
        v-if="showModalPriceComparison"
        v-shortkey="['esc']"
        class="modals-price-comparison modals-price-comparison_home-page"
        @shortkey="showModalPriceComparison = false"
      >
        <price-comparison :showModalPriceComparison.sync="showModalPriceComparison" />
      </div>
    </transition>
  </div>
</template>

<script>

import whiteTabList from '@/constants/whiteTabList';
import countryList from '@/constants/coutryList';
import socialList from '@/constants/socialList';
import Helpful from '@/components/Helpful';

const counter = () => import('@/components/counter/counter');
const Faq = () => import('@/components/faq/faq');
const priceComparison = () => import('@/components/priceComparison');
const WebsitesSlider = () => import('@/components/WebsitesSlider');

export default {
  name: 'Content',
  components: {
    counter,
    Faq,
    priceComparison,
    WebsitesSlider,
    Helpful,
  },
  props: [
    'isMobile',
    'showModalForm',
    'usa',
  ],
  data() {
    return {
      showModalPriceComparison: false,
      showExpand: false,
      dateEnd: new Date('2021-03-30T23:59:59'),
      whiteTabList,
      countryList,
      socialList,
      activeSlide: 0,
      swiperOption: {
        slidesPerView: 1,
        speed: 600,
        autoplay: true,
        pagination: {
          el: '.carousel-indicators',
          clickable: true,
        },
        navigation: {
          nextEl: '.carousel-control-next',
          prevEl: '.carousel-control-prev',
        },
        // on: {
        //   click: this.clickSwiper
        // }
      },
      swiperOptionSlider: {
        slidesPerView: 1,
        speed: 600,
        autoplay: true,
      },
      swiperOptionSliderWebsites: {
        slidesPerView: 4,
        speed: 600,
        autoplay: true,
        breakpoints: {
          1340: {
            slidesPerView: 3,
          },
          940: {
            slidesPerView: 2,
          },
          600: {
            slidesPerView: 'auto',
            spaceBetween: -70,
            centeredSlides: true,
          },
        },
      },
      observerDesigns: null,
      observerReachmore: null,
      websitesSlides: [
        { img: require('../home-assets/img/websites-slides/slide1.png'), link: 'https://www.impactfactors.app/' },
        { img: require('../home-assets/img/websites-slides/slide2.png'), link: '' },
        { img: require('../home-assets/img/websites-slides/slide3.png'), link: '' },
        { img: require('../home-assets/img/websites-slides/slide4.png'), link: '' },
      ],
    };
  },
  computed: {
    showModalFormStatus: {
      get() {
        return this.showModalForm;
      },
      set(data) {
        this.$emit('update:showModalForm', data);
      },
    },
    showModalPrivacyPolicy: {
      get() {
        return this.$store.getters.showModalPrivacyPolicy;
      },
      set(data) {
        this.$store.commit('setShowModalPrivacyPolicy', data);
      },
    },
    currentYear() {
      const today = new Date();
      const year = today.getFullYear();
      return year;
    },
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
  },
  beforeMount() {
    const currentDate = new Date();
    const differenceDate = this.dateEnd - currentDate;
    if (differenceDate < 0) {
      const differenceDay = Math.ceil(Math.abs(differenceDate) / (24 * 60 * 60 * 1000));
      const countDay = Math.ceil(differenceDay / 9);
      this.dateEnd.setDate(this.dateEnd.getDate() + countDay * 9);
    }
    // window.addEventListener('load', () => {
    //   console.log('load')
    //   const [preloaderWapper] = document.getElementsByClassName('preloader-wapper')
    //   if (preloaderWapper) {
    //     preloaderWapper.classList.add('loaded')
    //     setTimeout(() => {
    //       const [preloaderMain] = document.getElementsByClassName('preloader-main')
    //       preloaderMain.remove()
    //     }, 1000)
    //   }
    // })
  },
  beforeDestroy() {
    window.scroller.destroy();
    document.body.classList.remove('homepage');
    // const amoBtn = document.getElementsByClassName('amo-button-holder');
    // if (amoBtn && amoBtn.length) {
    //   amoBtn[0].remove();
    // }
  },
  mounted() {
    // if (window.scroller) {
    //   this.$nextTick(() => {
    //     window.scroller.init();
    //   });
    // }
    this.$nextTick(() => {
      if ('IntersectionObserver' in window) {
        this.observerDesigns = new IntersectionObserver((entries) => {
          const [el] = entries;
          if (el.isIntersecting) {
            this.$refs.designs.swiper.slideTo(this.activeSlide, 0);
            this.$refs.designs.swiper.autoplay.start();
          }
        }, { threshold: [0] });
        this.observerDesigns.observe(this.$refs.designs.$el);
      }

      if ('IntersectionObserver' in window) {
        this.observerReachmore = new IntersectionObserver((entries) => {
          const [el] = entries;
          if (el.isIntersecting) {
            this.$refs.reachmore.swiper.slideTo(this.activeSlide, 0);
            this.$refs.reachmore.swiper.autoplay.start();
          }
        }, { threshold: [0] });
        this.observerReachmore.observe(this.$refs.reachmore.$el);
      }
      // eslint-disable-next-line global-require
      /*
      const scrollTrigger = require('../home-assets/js/ScrollTrigger.min');
      console.log('scrollTrigger', scrollTrigger);
      const { ScrollTrigger } = scrollTrigger;
      window.ScrollTrigger = ScrollTrigger;
      // eslint-disable-next-line global-require
      window.gsap = require('../home-assets/js/gsap.min');
      */
      // eslint-disable-next-line global-require
      // const LocomotiveScroll = require('../home-assets/js/locomotive-scroll.min');
      // window.LocomotiveScroll = LocomotiveScroll;
      // eslint-disable-next-line global-require
      // window.plugins = require('../home-assets/js/plugins/plugins.min');
      // Active js
      // eslint-disable-next-line global-require
      // window.active = require('../home-assets/js/active');
      // eslint-disable-next-line global-require
      window.slider = require('../home-assets/js/slider');
      // Parallax animation
      // eslint-disable-next-line global-require
      // window.scrollParallax = require('../home-assets/js/scroll-parallax');
      // window.lottieAnimations = require('../home-assets/js/lottie-animations.js')
      // eslint-disable-next-line global-require
      window.resizing = require('../home-assets/js/resizing');
      /* Amo script */
      // const script = document.createElement('script');
      // script.innerHTML = '';
      // /* eslint-disable */
      //   const innerJsCode =
      //     document.createTextNode(`(function(a,m,o,c,r,m){a[m]={id:"39501",hash:"d57954a332ead35c950ee7a39226c22ed791ee2033189ed77dd8f346ff1ee50a",locale:"en",inline:false,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.amocrm.com/js/button.js?1616513092';d.head&&d.head.appendChild(s)}(window,0,'amoSocialButton',0,0,'amo_social_button'));`)
      //   /* eslint-enable */
      // script.appendChild(innerJsCode);
      // document.getElementById('amoBtn').appendChild(script);
      /* Amo script */
    });
    // window.fontAwesome = require('../home-assets/js/plugins/fontAwesome.js')
  },
  methods: {
    clickMethod(event, slide) {
      if (slide.link === '') {
        event.preventDefault();
      }
    },
    onClickFeature() {
      if (this.showExpand) {
        const sectionFeatures = document.getElementById('features');
        window.scroller.scrollTo(sectionFeatures);
        setTimeout(() => {
          this.showExpand = false;
        }, 520);
      } else {
        this.showExpand = true;
      }
    },
    showModalTemplatesMethod() {
      this.$store.state.showModalTemplates = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/utils/variables';

.websites-slider {;
  height: auto;

  &-bg {
    padding: 127px 0 132px 0;
    background: url(../assets/images/slider-bg.png) no-repeat center center;
    background-size: cover;

    @media (max-width: 991px) {
      padding: 48px 0 48px 0;
    }
  }

  &-container {
    max-width: 1264px;
    margin: 70px auto 0 auto;
    position: relative;

    @media (max-width: 991px) {
      margin-top: 10px;
    }
  }

  &-padding {
    padding-top: 20px;
  }

  &-img {
    max-width: 100%;
    height: 336px;
    box-shadow: 0 16px 20px rgba(0, 0, 0, .16);
  }
  &-button {
    display: inline-flex;
    justify-content: center;
    margin-top: 36px;
    align-items: center;
    width: 160px;
    height: 40px;
    border: 1px solid #E8E8E8;
    border-radius: 32px;
    background-color: #ffffff;
    color: #969696;
  }

  &-demo {
    position: absolute;
    top: -5px;
    left: calc(50% - 45px);
    font-size: 16px;
    font-weight: 600;
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.white-tab {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto!important;
  margin-right: auto!important;
  margin-top: 30px;
  padding: 57px 27px 54px 49px;

  @media (max-width: 1199px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 991px) {
    margin-left: 8px!important;
    margin-right: 8px!important;
    width: auto!important;
    padding-top: 33px!important;
    padding-bottom: 12px!important;
  }

}

.white-tab-item {
  position: relative;
  width: 33.33%;
  min-width: 33.33%;

  @media (max-width: 991px) {
    width: 50%;
    min-width: 50%;
  }

  &_website {
    padding-top: 9px;

    .white-tab-item__icon {
      margin-bottom: 21px!important;

      @media (max-width: 991px) {
        margin-bottom: 15px!important;
        max-width: 55px;
      }

    }
  }

  &_crm {
    @media (max-width: 991px) {
      padding-top: 7px;

      .white-tab-item__symbal {
        display: none;
      }
    }
  }

  &_heart {
    padding-top: 7px;
    padding-right: 6px;

    .white-tab-item__symbal {
      left: -18px;
    }

    @media (max-width: 991px) {

      .white-tab-item__icon {
        img {
          max-width: 38px !important;
        }
      }
    }
  }

  &_forms {
    padding-top: 6px;

    .white-tab-item__symbal {
      display: none;

      @media (max-width: 991px) {
        display: block;
        top: 27px;
      }

    }

    @media (max-width: 991px) {
      padding-left: 2px!important;

      .white-tab-item__icon {
        max-width: 42px !important;
      }
    }

  }

  &_bulb {

    .white-tab-item__icon {
      margin-bottom: 10px!important;
    }

    .white-tab-item__symbal {
      top: 56px;
      left: -18px;

      @media (max-width: 991px) {
        display: none;
      }

    }

  }

  &_sale-icon {
    .white-tab-item__symbal {
      top: 56px;

      @media (max-width: 991px) {
        top: 41px;
      }

    }

    .icon-box {
      padding-top: 4px!important;
    }

    @media (max-width: 991px) {
      padding-top: 10px !important;
      padding-left: 20px !important;

      .white-tab-item__icon {
        width: 92px !important;
        max-width: 92px !important;

        img {
          max-width: 100% !important;
        }

      }
    }

  }

  &__icon {
    margin-bottom: 16px!important;

    @media (max-width: 991px) {
      max-width: 38px ;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 13px!important;
    }

  }

  &__description {

    &_link {
      position: relative;
      display: inline-block;
      cursor: pointer;
      text-decoration: underline;
      transition: border-bottom-color .2s ease-in;

      &:hover {
        text-decoration: none;
      }

    }

  }

  @media (max-width: 991px) {
    &__title {
      font-size: 16px;
      margin-bottom: 2px;
    }

    &__description {
      font-size: 14px;
      line-height: 20px;
    }

    .icon-box {
      padding-top: 0!important;
    }

  }

  &__symbal {
    position: absolute;
    top: 65px;
    left: 4px;
    font-size: 2.85em;

    @media (max-width: 1199px) {
      left: -13px;
    }

    @media (max-width: 991px) {
      top: 39px;
      left: -5px!important;
      font-size: 1.85em;
    }

  }

}
//
//.pin-wrap-trigger, .pin-wrap2-trigger {
//  top: 0!important;
//}

.rocket-area-country {
  width: 100%;
  margin-bottom: 24px;
  padding: 102px 45px 45px;
  background: #FFFFFF;
  border-radius: 32px;
  text-align: center;

  @media (max-width: 991px) {
    padding: 46px 26px 27px;
  }

  &__title {
    width: 65%;
    margin: 0 auto 35px;
    font: 48px $font-global-bold;
    line-height: 56px;
    color: #1E2125;

    @media (max-width: 1199px) {
      width: 83%;
    }

    @media (max-width: 991px) {
      margin-bottom: 11px;
      font-size: 24px;
      line-height: 32px;
      width: 100%;

      span {
        display: block;
      }

    }

  }

  &__text {
    font: 18px $font-global;
    line-height: 32px;
    color: #969696;

    @media (max-width: 991px) {
      font-size: 16px;
    }
  }

}

.rocket-area-country-list {
  display: flex;
  margin: 0 -11px 29px;
  justify-content: center;

  @media (max-width: 991px) {
    width: 177px;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 9px;
  }

  &__item {
    padding: 11px;
    width: 92px;

    @media (max-width: 991px) {
      padding: 8px 7px;
      width: 59px;
    }

  }

}

.welcome-area {

  position: relative;

  @media (max-width: 767px) {
    padding-top: 80px;

    .container {
      position: relative;
    }

  }

  &__continent {
    position: absolute;
    width: calc(100% - 66px);
    max-width: 1013px;
    top: -1px;
    margin-left: 66px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;

    @media (max-width: 767px) {
      width: 405px;
      top: -70px;
      margin-left: 0;
    }

  }

}

.footer-bottom {
  text-align: center;
  padding-top: 75px;
  color: rgba(255, 255, 255, .75);

  @media (max-width: 767px) {
    padding-top: 44px;
    padding-bottom: 20px;
  }

}

.footer-verified {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 17px;
  font: 16px $font-global-medium;
  line-height: 20px;

  @media (max-width: 767px) {
    font-size: 14px;
  }

  img {
    margin-right: 4px;
  }

}

.footer-card {
  display: flex;
  justify-content: center;
  margin-bottom: 31px;

  @media (max-width: 767px) {
    margin-bottom: 17px;
  }

  &__item {
    width: 72px;
    padding: 0 9px;
    opacity: .75;

    @media (max-width: 767px) {
      width: 64px;
      padding: 0 8px;
    }

  }

}

.footer-info {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 24px;
  color: #969696;

  &__organization {
    color: inherit!important;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: -3px;

    &__organization {
      display: block;
    }

  }

  &__link {
    cursor: pointer;
    color: #C3C3C4!important;
    transition: color .2s ease-in;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: $color-white!important;
    }

  }

}

.footer-social {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }

  &__item {
    width: 38px;
    color: #C3C3C4;
    transition: color .2s ease-in;

    @media (max-width: 767px) {
      width: 32px;
    }

    &:hover {
      color: $color-white;
    }

    & + & {
      margin-left: 20px;

      @media (max-width: 767px) {
        margin-left: 16px;
      }

    }
  }

}

.navbar-inner {

  &__logo {
    display: none;
  }

}

@media (max-width: 991px) {
  .navbar-inner {
    display: flex;
    flex-direction: column;
    padding: 53px 40px 68px;
    width: 19rem!important;

    .navbar-toggler {
      left: auto!important;
      right: 30px;
      top: 20px !important;
    }

    &__logo {
      display: block;
      width: 180px;
      margin-bottom: 23px;
    }

    nav {
      margin-top: auto;

      .btn {
        width: 100%;
        margin: 0;

        & + .btn {
          margin-top: 15px;
        }

      }

    }

  }
}

.navbar-inner-navigation {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }

  &__item {
    padding: 5px 0;
    font: 16xp / 48px $font-global;
    transition: color .2s ease-in;

    &:hover {
      color: $color-turquoise;
    }

    &_active {
      pointer-events: none;
      color: $color-turquoise;
    }

  }

}

.pin-wrap2 {
  .section-slide1, .section-slide2, .section-slide3 {
    position: relative;
    overflow: hidden;
  }

  @media (min-width: 1025px) {

    .section-row {
      position: relative;
      z-index: 2;
    }

  }

  .s_desktop {
    display: block !important;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: none;
    width: auto!important;
    z-index: 1;
  }

  .s_mobile {
    //position: absolute;
    //top: 0;
    //left: 50%;
    //transform: translateX(-50%);
    //max-width: none;
    //width: auto!important;
    //z-index: 1;
  }
}

.carousel-item {
  float: none!important;
  display: block!important;
  margin-right: 0!important;
  backface-visibility: visible!important;
}

.rocket-area {
  padding-bottom: 72px;
}

.pin-wrap,
.pin-wrap2 {
  width: 100%;
}

</style>

<style lang="scss">
.modals-price-comparison_home-page {
  @media (max-width: 1199px) {
    .price-comparison-bg {
      padding: 20px;
      max-width: 100%;
    }

    .price-comparison {
      max-width: 100%;
    }

    .transactions-item {
      max-width: 50%;
    }

  }

  @media (max-width: 1100px) {
    .price-comparison {
      padding: 30px;

      .price-comparison__title {
        font-size: 21px;
        line-height: 1.2;
      }

      .platforms {
        display: block;
        width: 100%;
        height: auto;

        .platforms-content {
          max-width: 100% !important;
        }

      }

      .platforms-between {
        width: calc(100% - 60px);
        left: 30px;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        margin-top: 15px;

        span {
          margin: 0 35px;
        }

      }

      .platforms-between:before, .platforms-between:after {
        width: 100%;
        height: 1px;
        flex-grow: 1;
        position: static;
      }

      .platforms-content {
        flex-direction: column;

        .platforms-left {
          order: 0;
        }

        .platforms-between {
          position: static!important;
          transform: none!important;
          order: 1;
        }

        .platforms-right {
          order: 2;
          margin-top: 25px!important;
        }

        > div {
          width: 100%;
        }

      }

    }

    .transactions {
      margin: 0!important;
    }

    .transactions-item + .transactions-item {
      margin-left: 15px;
    }

    .transactions-item__slider {
      width: 100%!important;
    }

    .savings {
      margin-left: 0!important;
      margin-right: 0!important;
    }

    .price-comparison__desc {
      margin-left: 0!important;
      margin-right: 0!important;
    }

  }

  @media (max-width: 991px) {

    .transactions-item {
      max-width: 100% !important;

      & + .transactions-item {
        margin-left: 0;
        margin-top: 40px;
      }

    }

    .transactions {
      display: block!important;
    }

    .savings {
      margin-top: 25px!important;
    }

  }

  @media (max-width: 767px) {
    .price-comparison__title {
      font-size: 18px!important;
    }

    .price-comparison__info {
      font-size: 16px!important;
      line-height: 1.5!important;

      span {
        font-size: 18px!important;
      }

      div {
        font-size: 17px!important;
      }

    }

    .platforms__title, .transactions-item__title {
      font-size: 16px!important;
    }

    .savings__sum {
      font-size: 21px!important;
    }

    .savings__year {
      font-size: 15px!important;
      top: 0!important;
    }
  }

  @media (max-width: 600px) {

    .savings {
      padding: 20px 30px!important;
    }

    .savings-content {
      flex-direction: column;
      align-items: center;
    }

    .savings-left, .savings-right {
      order: 0;
      width: 100%!important;
      text-align: center!important;
      margin-right: 0!important;
    }

    .savings-right {
      order: 2;
      margin-top: 22px!important;
    }

    .savings-between {
      order: 1;
      position: static!important;
      width: calc(100% - 60px)!important;
      display: flex!important;
      align-items: center!important;
      transform: none !important;
      margin-top: 17px!important;

      &:before, &:after {
        position: static!important;
        width: 100%!important;
        height: 1px!important;
      }

      span {
        margin: 0 20px;
      }

    }

  }

  @media (max-width: 500px) {
    .transactions-item {
      display: block!important;
    }

    .transactions-item__slider {
      margin-top: 20px!important;
    }

    .transactions-item__block {
      width: 136px!important;
      margin: 20px auto 0;
      display: block;

    }

    .transactions-item__input {
      text-align: center;
      margin: 20px auto 0;
      display: block;
    }

  }

  @media (max-width: 500px) {
    .price-comparison {
      padding-left: 15px!important;
      padding-right: 15px!important;

      .platforms-list {
        display: block;
      }

      .radio-item {
        margin-left: 0!important;
      }

      .platforms__btn {
        margin-top: 22px;
      }

      .platforms-right {
        margin-top: 60px!important;
      }

    }
  }
}
</style>

<!-- Created by zas on 05.05.2021  -->
