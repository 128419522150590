// import LocomotiveScroll from 'locomotive-scroll';
// import { gsap, ScrollTrigger } from "gsap/all";
// gsap.registerPlugin(ScrollTrigger);
const isPhone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)
// const pageContainer = document.querySelector('.main-scroll');
// const pageContainer = document.body
/* SMOOTH SCROLL */
// const scroller = new LocomotiveScroll({
//   el: pageContainer,
//   smooth: false
// });
const scroller = window
window.scroller = scroller

const updateScrollUp = () => {
  // console.log('scroll', scroll)
  // const scroll = scroller.scrollY
  const scroll = window.scrollY
  const scrollBtn = document.getElementById('scrollUp')
  if (scrollBtn) {
    scrollBtn.style.display = scroll > 300 ? 'block' : 'none'
  }
  // if (scroll > 300) {
  //   $("#scrollUp").fadeIn(500);
  // } else {
  //   $("#scrollUp").fadeOut(500);
  // }
}

// const scrollBtn = document.getElementById('scrollUp')
//
// scrollBtn.onclick = function () {
//   scroller.scrollTo(0, 0)
// };

// scroller.on("scroll", ScrollTrigger.update);
// const $el = $('.preview-area');
const [el] = document.getElementsByClassName('preview-area')
console.log('el', el);

function scrollRotate() {
  const y = scroller.scrollY
  let image1 = document.getElementById("star-animation-1");
  image1.style.transform = "rotate(" + y/2 + "deg)";
  let image2 = document.getElementById("star-animation-2");
  image2.style.transform = "rotate(" + y/2 + "deg)";
  let image3 = document.getElementById("star-animation-3");
  image3.style.transform = "rotate(" + y + "deg)";
  let image4 = document.getElementById("star-animation-4");
  image4.style.transform = "rotate(" + y + "deg)";

}

scroller.addEventListener('scroll', () => {
  // updateScrollUp();
  scrollRotate();
  const scroll = scroller.scrollY
  el.style.backgroundPosition = '50% '+(350-.5*scroll)+'%'
  // $el.css({
  //   'background-position':'50% '+(350-.5*scroll)+'%'
  // });
})
/*
ScrollTrigger.scrollerProxy(pageContainer, {
  scrollTop(value) {
    const scrollY = scroller.scrollY
    updateScrollUp(scrollY)
    return arguments.length ?
        scroller.scrollTo(0, value) :
        scrollY;
  },
  getBoundingClientRect() {
    return {
      left: 0,
      top: 0,
      width: window.innerWidth,
      height: window.innerHeight
    };
  },
  pinType: "fixed"
});

////////////////////////////////////
////////////////////////////////////
(function() {
  gsap.to(".pin-wrap", {
    scrollTrigger: {
      scroller: pageContainer, //locomotive-scroll
      scrub: true,
      trigger: ".pin-wrap-trigger",
      pin: true,
      start: "center center",
    },
    x: () => {
      const slideCount = document.querySelectorAll(".pin-wrap > div").length
      return (-100 / slideCount) * (slideCount - 1) + "%"
    },
    ease: "none"
  });


  gsap.to(".pin-wrap2", {
    scrollTrigger: {
      scroller: pageContainer, //locomotive-scroll
      scrub: true,
      trigger: ".pin-wrap2-trigger",
      pin: true,
      start: "center center",
    },
    x: () => {
      const slideCount = document.querySelectorAll(".pin-wrap2 > div").length
      return (-100 / slideCount) * (slideCount - 1) + "%"
    },
    ease: "none"
  });

  // ScrollTrigger.addEventListener("refresh", () => {
  //   scroller.update()
  // }); //locomotive-scroll

  ScrollTrigger.refresh();
  if (!isPhone) {
    window.addEventListener('resize', () => {
      ScrollTrigger.refresh()
    });
    setInterval(() => {
      ScrollTrigger.refresh()
    }, 1000)
  } else {
    ScrollTrigger.config({
      // default is "resize,visibilitychange,DOMContentLoaded,load" so we can remove "resize" from the list:
      autoRefreshEvents: "visibilitychange,DOMContentLoaded,load"
    });
  }
})();
*/
