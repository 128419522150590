const COUNTRY_LIST = [
  {
    id: 1,
    img: 'flag1.svg',
  },
  {
    id: 2,
    img: 'flag2.svg',
  },
  {
    id: 3,
    img: 'flag3.svg',
  },
  {
    id: 4,
    img: 'flag4.svg',
  },
  {
    id: 5,
    img: 'flag5.svg',
  },
  {
    id: 6,
    img: 'flag6.svg',
  },
  {
    id: 7,
    img: 'flag7.svg',
  },
  {
    id: 8,
    img: 'flag8.svg',
  },
  {
    id: 9,
    img: 'flag9.svg',
  },
];

export default COUNTRY_LIST;
