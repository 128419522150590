<template>
  <section class="section helpful">
    <div class="container">
      <h2 class="text-black text-center helpful__title">
        Helpful Resources to Get You Started
      </h2>
      <div class="helpful-content">
        <div class="helpful-list">
          <div
            v-for="item in helpfulList"
            :key="item.id"
            class="helpful-list-item"
            @click="showModalVideo = item.id"
          >
            <div
              class="helpful-list-item__title"
              v-html="item.title"
            />
            <div
              v-if="item.subtext"
              class="helpful-list-item__subtext"
            >
              {{ item.subtext }}
            </div>
            <div
              class="helpful-list-item__duration helpful-duration"
            >
              <div class="helpful-duration__icon">
                <PlayIcon />
              </div>
              <div class="helpful-duration__text">
                {{ item.duration }}
              </div>
            </div>
          </div>
        </div>
        <div class="helpful-sign-up">
          <div class="helpful-sign-up__icon">
            <img
              :src="require('./../assets/images/fankYou.svg')"
            >
          </div>
          <div class="helpful-sign-up__title">
            How to Sign Up <br> & Your Next Steps!
          </div>
          <div class="helpful-sign-up__duration helpful-duration">
            <div class="helpful-duration__icon">
              <PlayIcon />
            </div>
            <div
              class="helpful-duration__text"
              @click="showModalVideo = 5"
            >
              Watch 2 minute video
            </div>
          </div>
          <div class="helpful-duration__button">
            <button
              class="btn btn-second"
              @click="showModalGetStarted = true"
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
    <transition-group
      v-for="item in helpfulList"
      :key="item.id"
      name="fade-modal"
      appear
    >
      <modalsVideo
        v-if="showModalVideo === item.id"
        :key="item.id"
        :videoUrl="item.url"
        :showModalVideo.sync="showModalVideo"
      >
      </modalsVideo>
    </transition-group>
    <transition
      name="fade-modal"
      appear
    >
      <modalsVideo
        v-if="showModalVideo === 5"
        :videoUrl="'https://player.vimeo.com/video/555204934?h=2254f332cc'"
        :showModalVideo.sync="showModalVideo"
      >
      </modalsVideo>
    </transition>
  </section>
</template>

<script>
import modalsVideo from '@/components/modals/modalsVideo';
import helpfulList from '../constants/helpfulList';
import { PlayIcon } from '../constants/icon';

export default {
  name: 'Helpful',
  components: {
    PlayIcon,
    modalsVideo,
  },
  data() {
    return {
      helpfulList,
      showModalVideo: false,
    };
  },
  computed: {
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/utils/variables";

.helpful {
  padding: 111px 0 136px;

  @media (max-width: 767px) {
    padding: 57px 0 56px;
  }

  &__title {
    max-width: 587px;
    margin: 0 auto 72px;
  }

}

.helpful-content {
  display: grid;
  grid-template-columns: auto 360px;
  grid-gap: 24px;

  @media (max-width: 1200px) {
    grid-template-columns: auto;
    justify-content: center;
  }

  @media (max-width: 767px) {
    grid-gap: 16px;
  }

}

.helpful-list {
  display: grid;
  grid-template-columns: repeat(2, 360px);
  grid-template-rows: repeat(2, 168px);
  grid-gap: 24px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 328px);
    grid-template-rows: repeat(2, 128px);
  }

  @media (max-width: 767px) {
    grid-template-columns: auto;
    grid-template-rows: repeat(4, 128px);
    grid-gap: 16px;
  }
}

.helpful-list-item {
  display: flex;
  flex-direction: column;
  border: 1px solid $color-light-grey;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 26px 31px 19px;

  @media (max-width: 991px) {
    width: 100%;
    min-width: 328px;
    max-width: 360px;
    margin: 0 auto;
    padding: 20px 21px 17px;
  }

  &__title {
    font: 22px/30px $font-global-bold;
    color: $color-shark2;

    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 26px;
    }

  }

  &__subtext {
    margin-top: 6px;
    font: 18px $font-global;
    color: $color-dusty-gray3;

    @media (max-width: 991px) {
      margin-top: 0;
      font: 18px/26px $font-global-bold;
      color: $color-shark2;
    }

  }

  &__duration {
    margin-top: auto;
  }
}

.helpful-duration {
  font-size: 16px;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  color: $color-bright-turquoise;
  cursor: pointer;
  transition: opacity .2s ease-in;

  &:hover {
    opacity: .7;
  }

  &__icon {
    display: flex;
  }

}

.helpful-sign-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $color-light-grey;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 28px 31px 16px;

  @media (max-width: 1200px) {
    width: 100%;
    min-width: 328px;
    max-width: 360px;
    margin: 0 auto;
  }

  @media (max-width: 991px) {
    padding: 20px 21px 40px;
  }

  &__icon {
    display: block;
    width: 120px;
    margin-bottom: 8px;

    @media (max-width: 991px) {
      margin-bottom: 13px;
    }

  }

  &__title {
    font: 22px/30px $font-global-bold;
    max-width: 200px;
    color: $color-shark2;
    text-align: center;

    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 26px;
    }

  }

  &__duration {
    margin-top: 7px;
    margin-bottom: 17px;
  }

  .btn {
    width: 186px;
    height: 50px;
    font: 18px $font-global-bold;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
